/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import shevy from "../styleguide/typography";

import DefaultLayout from "../layouts/default";
import SEO from "../components/seo";
import Wrapper from "../components/wrapper";
import Article from "../components/article";
import {
    Paginator,
    PaginatorItem,
    PaginatorItemDivider,
    PaginatorLink,
} from "../components/pagination";

const BlogPost = ({ data, pageContext, path }) => {
    const tags = data.mdx.frontmatter.tags?.split(",");
    const isLegacy = tags?.indexOf("legacy") >= 0;

    const thumbnail = data.mdx.frontmatter.thumbnail?.childImageSharp.fluid.src;

    return (
        <DefaultLayout>
            <SEO
                title={data.mdx.frontmatter.title}
                thumbnailPath={thumbnail}
                path={path}
                type="article"
                meta={[
                    {
                        name: `article:published_time`,
                        content: data.mdx.frontmatter.rawDate,
                    },
                ]}
            />
            <Wrapper
                css={css`
                    background: rgba(255, 255, 255, 0.6);
                    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.06);
                    display: flex;
                    flex-direction: column;
                    padding-top: ${shevy.baseSpacing(3)};
                `}
            >
                {isLegacy && (
                    <div
                        css={css`
                            background: rgba(252, 247, 187, 0.9);
                            font-size: 0.8rem;
                            line-height: ${shevy.lineHeightSpacing(1)};
                            margin: 0;

                            @media (max-width: 550px) {
                                padding: ${shevy.baseSpacing(1)}
                                    ${shevy.baseSpacing(1)};
                            }

                            @media (min-width: 550px) {
                                padding: ${shevy.baseSpacing(1)}
                                    ${shevy.baseSpacing(2)};
                            }
                        `}
                    >
                        This post has been marked as legacy. It is not updated,
                        and there may be inaccuracies in the content.
                    </div>
                )}

                <div
                    css={css`
                        @media (max-width: 550px) {
                            padding: ${shevy.baseSpacing(2)}
                                ${shevy.baseSpacing(1)};
                        }

                        @media (min-width: 550px) {
                            padding: ${shevy.baseSpacing(2)}
                                ${shevy.baseSpacing(2)};
                        }
                    `}
                >
                    <Article
                        title={data.mdx.frontmatter.title}
                        rawDate={data.mdx.frontmatter.rawDate}
                        formattedDate={data.mdx.frontmatter.formattedDate}
                        timeToRead={data.mdx.timeToRead}
                    >
                        <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </Article>
                </div>

                <Paginator>
                    <PaginatorItem>
                        {pageContext.nextPost && (
                            <PaginatorLink
                                label="← Next"
                                to={pageContext.nextPost.path}
                            >
                                {pageContext.nextPost.title}
                            </PaginatorLink>
                        )}
                    </PaginatorItem>
                    <PaginatorItemDivider />
                    <PaginatorItem>
                        {pageContext.previousPost && (
                            <PaginatorLink
                                label="Previous →"
                                to={pageContext.previousPost.path}
                            >
                                {pageContext.previousPost.title}
                            </PaginatorLink>
                        )}
                    </PaginatorItem>
                </Paginator>
            </Wrapper>
        </DefaultLayout>
    );
};

export const blogPostQuery = graphql`
    query BlogPostQuery($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            id

            frontmatter {
                title

                rawDate: date
                formattedDate: date(formatString: "MMMM DD, YYYY")

                description
                tags

                thumbnail {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            src
                        }
                    }
                }
            }

            body
            timeToRead
        }
    }
`;

export default BlogPost;
